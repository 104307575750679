<!-- =========================================================================================
    File Name: ChatContact.vue
    Description: Chat contact - single component for chat
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="chat__contact flex items-center px-2 py-3" :class="{ 'bg-primary-gradient text-white shadow-lg': isActiveChatUser }">
    <div class="contact__avatar mr-3">
      <vs-avatar class="border-2 border-solid border-white" :src="getUserImage(contact.userId)" size="42px"></vs-avatar>
    </div>
    <div class="contact__container w-full flex items-center justify-between overflow-hidden">
      <div class="contact__info flex flex-col truncate w-5/6">
        <h5 class="font-semibold" :class="{ 'text-white': isActiveChatUser }">
          {{ getUserName(contact.userId) }}
        </h5>
        <span class="truncate">{{ contact.userData.location }}</span>
        <p class="text-primary text-sm font-semibold" :class="{ 'text-white': isActiveChatUser }">
          {{ contact.jobData.jobTitle }}
        </p>
      </div>

      <div class="chat__contact__meta flex self-start flex-col items-end w-1/6">
        <span class="whitespace-no-wrap">{{ lastMessaged | date }}</span>
        <vs-avatar v-if="unseenMsg !== 0" color="primary" :text="`${unseenMsg}`" size="25px" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    contact: {
      type: Object,
      required: true,
    },
    lastMessaged: {
      type: String,
      default: '',
    },
    unseenMsg: {
      type: Number,
      default: 0,
    },
    isActiveChatUser: {
      type: Boolean,
    },
  },
  methods: {
    getUserName(userId) {
      let user = this.$store.getters['userModule/getUser'][userId];
      if (user.firstName && user.firstName !== '' && user.lastName && user.lastName !== '') {
        return `${user.firstName} ${user.lastName}`;
      } else {
        return user.fullName;
      }
    },
    getUserImage(userId) {
      let user = this.$store.getters['userModule/getUser'][userId];
      return user.profileImage;
    },
  },
};
</script>

<style lang="scss">
.vs-avatar {
  object-fit: cover !important;
}
</style>
